import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import styled from "styled-components"

const ShippingBuyPage = ({intl, title, description, icon, color, className}) => {
    return (
            <ShippingSectionWrapper color={color} className={className}>
                {icon}
                <div class="DescriptionWrapper">
                    <div className="Title">{title}</div>
                    <div className="Description">{description}</div>
                </div>
            </ShippingSectionWrapper>
    )
}


const ShippingSectionWrapper = styled.div`
  display: flex;
  padding: 16px 12px;
  flex-direction: column;
  background-color: ${props => (props.color ? props.color : props.theme.color.neutrals.neutral0)};;
  border-radius: 20px;
  gap: 8px;
  align-content: center;
  align-items: center;
  @media (min-width: ${props => props.theme.screen.xl}) {
    flex-direction: row;
    width: 50%;
  }

  svg {
    min-width: 48px;
    min-height: 48px;
  }
  
  .DescriptionWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
    @media (min-width: ${props => props.theme.screen.xl}) {
      margin-left: 8px;
      text-align: center;
    }
  } 
  
  .Title {
    margin-top: 8px;
    ${({theme}) => theme.font_size.regular};
    font-family: ${({theme}) => theme.font.bold};
  }

  .Description {
    ${({theme}) => theme.font_size.small};
    font-family: ${({theme}) => theme.font.thin};
  }
`


export default injectIntl(ShippingBuyPage)
